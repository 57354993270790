<template>
    <div
        id="brand-header-animated"
        class="brand-header-container"
        @click="enterPage"
    >
        <div
            data-scroll
            data-scroll-id="brandHeader"
            class="header-bg-container"
        >
            <div
                @click="enterPage"
                v-observer:callback="observerConfig"
                class="header clipped"
            >
                <div class="background-image">
                    <img
                        :class="{ zoom: zoomActive }"
                        v-if="!video"
                        :src="coverImage"
                        data-scroll
                        data-scroll-id="scale"
                        class="story-image"
                        alt="story"
                    />
                    <div
                        v-else
                        class="video-scale-container"
                        data-scroll
                        data-scroll-id="scale"
                    >
                        <video
                            @click="showVideo(fullVideo)"
                            :class="{
                                zoom: zoomActive,
                                active: scrollBreak
                            }"
                            muted
                            autoplay
                            loop
                            playsinline
                            :poster="coverImage"
                        >
                            <source :src="video" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div
                    :style="{ top: logoTopPosition + 'px' }"
                    class="logo-container"
                >
                    <div v-html="pageLogo"></div>
                </div>
            </div>
        </div>
        <div
            :style="{ top: logoTopPosition + 'px' }"
            class="animate-logo-container"
        >
            <div v-html="pageLogo"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import imagesLoaded from "imagesloaded";
// import MouseChanger from "@/components/custom/MouseChanger";
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        // MouseChanger
    },
    data() {
        return {
            zoomActive: false,
            scrollInterval: null,
            circleRadius: 250,
            delta: 250,
            logoTopPosition: 0,
            initTimeout: null,
            resizeTimeout: null
        };
    },
    computed: {
        ...mapGetters({
            scroll: "scroll/getScroll",
            device: "resize/geDevice",
            menu: "menu/getCurrentPageMenuData",
            lang: "language/getLang",
            getPageIsLoaded: "menu/getPageIsLoaded",
            scrollBreak: "video/getScrollBreak"
        }),
        getType() {
            if (this.scrollBreak) {
                return this.mouseTypes.VideoScale;
            } else {
                return this.mouseTypes.ImageScale;
            }
        },
        maxRadius() {
            return (
                Math.sqrt(
                    Math.pow(this.device?.size, 2) +
                        Math.pow(this.device?.innerHeight + 100, 2)
                ) / 2
            );
        },
        observerConfig() {
            return {
                callBack: this.initTitleAnimation,
                animateOnObserve: true
            };
        },
        clipSize() {
            let size = 250;
            if (this.device?.size < 1441) {
                size = 200;
            }

            if (this.device?.size < 769) {
                size = 150;
            }

            return size;
        },
        pageLogo() {
            return this.menu?.titles?.[this.lang]?.svg_regular;
        },
        video() {
            if (window.innerWidth < 1024) {
                return this.data?.list?.[0]?.mobile_video_intro?.videoFile?.url;
            } else {
                return this.data?.list?.[0]?.video_intro?.videoFile?.url;
            }
        },
        fullVideo() {
            return {
                video: this.data?.list?.[0]?.video?.videoFile?.url,
                mobileVideo: this.data?.list?.[0]?.mobile_video?.videoFile?.url
            };
        },
        coverImage() {
            return this.menu?.media?.cover?.devices?.[this.device?.type];
        },
        brandHeaderScrollTop() {
            let top = 200;
            if (this.device.size < 1441) {
                top = 150;
            }
            if (this.device.size < 1023) {
                top = 155;
            }
            if (this.device.size < 768) {
                top = 110;
            }
            return top;
        }
    },
    methods: {
        ...mapActions({
            setContent: "video/setContent",
            setScrollBreak: "video/setScrollBreak"
        }),
        showVideo(path) {
            if (this.scrollBreak) {
                if (window.innerWidth < 1024) {
                    this.setContent({
                        path: path.mobileVideo
                    });
                } else {
                    this.setContent({
                        path: path.video
                    });
                }
            } else {
                this.enterPage();
            }
        },
        setLogoPosition() {
            this.$nextTick(() => {
                let height = document
                    .querySelector(".logo-container")
                    ?.getBoundingClientRect()?.height;
                let innerheight = document
                    .querySelector("#brand-header-animated")
                    ?.getBoundingClientRect()?.height;

                this.logoTopPosition = (innerheight - height) / 2;
            });
        },
        disableScroll() {
            if (this.scroll) {
                if (this.scrollInterval)
                    cancelAnimationFrame(this.scrollInterval);
                this.scroll.stop();
                return;
            }
            this.scrollInterval = requestAnimationFrame(this.disableScroll);
        },
        enterPage() {
            this.setScrollBreak(true);
            this.removeMouseWheelHandler();
            this.gsap.to(".clipped", {
                clipPath: `circle(${this.maxRadius}px at 50%)`,
                ease: this.CustomEase.create(
                    "custom",
                    "M0,0,C0.098,0.544,0.306,0.812,1,1"
                ),
                overwrite: true,
                duration: 1.3,
                onComplete: () => {
                    this.scroll?.start();
                }
            });
        },
        removeMouseWheelHandler() {
            window.removeEventListener("wheel", this.mousewheel);
            window.removeEventListener("touchstart", this.mousewheel);
        },
        initAnimation() {
            let duration = 0.6;
            let ease = this.CustomEase.create(
                "custom",
                "M0,0,C0.074,0.628,0,1,1,1"
            );
            this.gsap.fromTo(
                ".header-bg-container .header",
                {
                    opacity: 0,
                    clipPath: `circle(${this.clipSize / 2}px at 50%)`
                },
                {
                    clipPath: `circle(${this.clipSize}px at 50%)`,
                    opacity: 1,
                    duration,
                    delay: 0.1,
                    ease
                }
            );
            this.gsap.fromTo(
                ".header-bg-container .logo-container",
                {
                    scale: 0.5,
                    opacity: 0
                },
                {
                    scale: 1,
                    opacity: 1,
                    delay: 0.25,
                    duration,
                    ease,
                    onComplete: () => {
                        this.mousewheelHandler();
                    }
                }
            );
        },
        mousewheel() {
            this.zoomActive = true;

            this.enterPage();
            if (this.device.size < 1024) {
                this.gsap.set(".root", {
                    clearProps: "overflow"
                });
            }
        },
        mousewheelHandler() {
            // this.setScrollBreak(true);
            window.addEventListener("wheel", this.mousewheel, {
                passive: true
            });
            window.addEventListener("touchstart", this.mousewheel, {
                passive: true
            });
        },
        resize() {
            if (this.resizeTimeout) clearTimeout(this.resizeTimeout);

            this.resizeTimeout = setTimeout(() => {
                this.setLogoPosition();
                this.gsap.set(".logo-container", {
                    y: 0
                });
                this.gsap.set(".animate-logo-container", {
                    y: 0
                });
                this.enterPage();
            }, 100);
        },
        resizeHandler() {
            window.addEventListener("resize", this.resize);
        },
        mobileAnimation() {
            if (this.device.size < 1024) {
                this.initAnimation();
                this.gsap.set(".root", {
                    overflow: "hidden"
                });
            }
        }
    },
    watch: {
        getPageIsLoaded: {
            handler: function(val) {
                if (val) {
                    this.initAnimation();
                }
            }
        },
        scroll: {
            handler: function() {
                this.disableScroll();
            }
        }
    },
    mounted() {
        imagesLoaded(document.querySelector("#brand-header-animated"), () => {
            this.setLogoPosition();
            this.resizeHandler();
            this.mobileAnimation();
            let scrollElement = document.querySelector("body");
            scrollElement.addEventListener("scroll", () => {
                let a = scrollElement.pageYOffset || scrollElement.scrollTop;
                let innerheight = document
                    .querySelector("#brand-header-animated")
                    ?.getBoundingClientRect()?.height;
                let top = this.brandHeaderScrollTop + innerheight / 2 + 20;
                let maxDistance = top;
                if (a > maxDistance) a = maxDistance;

                this.gsap.set(".logo-container,.animate-logo-container", {
                    y: a
                });
            });
        });
    },
    beforeDestroy() {
        this.scroll?.start();
        this.removeMouseWheelHandler();
        window.removeEventListener("resize", this.resize);
        if (this.initTimeout) clearTimeout(this.initTimeout);
    }
};
</script>

<style lang="scss" scoped>
.brand-header-container /deep/ {
    overflow: hidden;

    .animate-logo-container {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        will-change: transform;
        backface-visibility: hidden;
        //top: calc(50vh - 75px) !important;
        @media only screen and (max-width: 1023px) {
            position: absolute;
            img,
            svg {
                width: 200px;
            }
        }

        svg {
            width: 308px;
            backface-visibility: hidden;
            will-change: transform;
            @media only screen and (max-width: 1439px) and (min-width: 768px) {
                width: 220px;
            }
            @media only screen and (max-width: 767px) {
                width: 213px;
            }

            path {
                backface-visibility: hidden;
                will-change: transform;
                fill: #0a141c;
            }
        }
    }

    .header-bg-container {
        background: #0a141c;
        z-index: 12;
        position: relative;
        overflow: hidden;
        pointer-events: none;
    }

    .header {
        position: relative;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        overflow: hidden;
        clip-path: circle(0px at 50%);
        opacity: 0;
        z-index: 10;

        .background-image {
            position: absolute;
            width: 115%;
            height: 100vh;
            left: -7.5%;
            img {
                transition: 0.6s;
                &.zoom {
                    transform: scale(1.2);
                }
            }

            .story-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .logo-container {
            position: absolute;
            left: 50%;
            top: calc(50vh - 38px);
            transform: translateX(-50%);
            will-change: transform;
            -webkit-backface-visibility: hidden;

            svg {
                fill: white;
                width: 308px;
                @media only screen and (max-width: 1439px) and (min-width: 768px) {
                    width: 220px;
                }
                @media only screen and (max-width: 767px) {
                    width: 213px;
                }

                path {
                    fill: white;
                }
            }
        }
    }

    .video-scale-container {
        width: 100%;
        height: 100vh;

        video {
            height: 100vh;
            width: 100%;
            object-fit: cover;
            transition: 0.6s;
            pointer-events: none;
            &.active {
                pointer-events: all;
            }
            &.zoom {
                transform: scale(1.2);
            }
        }
    }
}
</style>
