<template>
    <div data-scroll data-scroll-id="page-header" class="page-header">
        <div class="background-image" :class="{ pointer: active }">
            <picture>
                <source
                    class="story-image"
                    media="(max-width: 767px)"
                    :srcset="coverImage.mobile"
                />
                <img
                    :src="coverImage.desktop"
                    class="story-image"
                    alt="story"
                />
            </picture>
            <grain-effect />
        </div>
        <span :class="{ active: active }" class="teaser" v-html="teaser"></span>
        <div class="scroll-container">
            <span class="title">{{ _tr("Scroll") }}</span>
            <svg
                width="11.775"
                height="22.23"
                class="icon"
                viewBox="0 0 11.775 22.23"
            >
                <g
                    id="Group_55"
                    data-name="Group 55"
                    transform="translate(11.068 21.523) rotate(180)"
                >
                    <line
                        id="Line_3"
                        data-name="Line 3"
                        x2="5.192"
                        y2="5.431"
                        transform="translate(5.17 0)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="1"
                    />
                    <line
                        id="Line_4"
                        data-name="Line 4"
                        x1="5.17"
                        y2="5.431"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="1"
                    />
                    <line
                        id="Line_5"
                        data-name="Line 5"
                        y2="20.265"
                        transform="translate(5.181 0.759)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="1"
                    />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
import GrainEffect from "@/components/custom/GrainEffect";
import { mapGetters } from "vuex";

export default {
    props: {
        config: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            active: true
        };
    },
    components: {
        GrainEffect
    },
    created() {
        setTimeout(() => {
            this.mobileScroll();
        }, 1000);
    },
    computed: {
        ...mapGetters({
            getCurrentPageMenuData: "menu/getCurrentPageMenuData",
            device: "resize/geDevice",
            lang: "language/getLang",
            scroll: "scroll/getScroll"
        }),
        coverImage() {
            return {
                desktop: this.getCurrentPageMenuData?.media?.cover?.devices?.[
                    this.device?.type
                ],
                mobile: this.getCurrentPageMenuData?.media?.optional_image
                    ?.devices?.desktop
            };
        },
        teaser() {
            return this.getCurrentPageMenuData?.titles?.[this.lang]?.teaser;
        }
    },
    methods: {
        mobileScroll() {
            if (this.scroll === null) {
                return;
            }
            this.scroll.on("scroll", args => {
                if (args.scroll.y > 180) {
                    this.active = false;
                } else {
                    this.active = true;
                }
            });
        }
    }
};
</script>

<style lang="scss">
.page-header {
    position: relative;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;

    .background-image {
        &.pointer {
            cursor: pointer;
        }
        position: absolute;
        width: 115%;
        height: 100vh;
        left: -7.5%;

        .story-image {
            width: 100%;
            height: 100%;
            transform: scale(1.2, 1.2);
            object-fit: cover;
        }
    }

    .teaser {
        left: 105px;
        color: white;
        font-size: 55px;
        position: absolute;
        bottom: 111px;
        font-weight: normal;
        text-align: left;
        padding-right: 345px;
        opacity: 0;
        will-change: transform;
        transition: 0.6s;
        &.active {
            opacity: 1;
        }
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            left: 195px;
            padding-right: 325px;
            font-size: 34px;
            bottom: 100px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            left: 158px;
            font-size: 21px;
            bottom: 80px;
            padding-right: 318px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            left: 97px;
            font-size: 21px;
            bottom: 80px;
            padding-right: 287px;
        }
        @media only screen and (max-width: 1023px) {
            left: 50%;
            width: 100%;
            padding: 0 40px;
            display: block;
            bottom: 185px;
            transform: translateX(-50%);
            font-size: 21px;
            line-height: 40px;
            text-align: center;
        }
        @media only screen and (max-width: 767px) {
            bottom: 165px;
        }

        span {
            color: #e5b676;
        }
    }

    .scroll-container {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 55px;

        @media only screen and (max-width: 1023px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title {
            color: #e5b676;
            font-size: 16px;
            letter-spacing: 4.2px;
            display: block;
            margin-bottom: 7.5px;
        }
    }
}
</style>
