<template>
    <div data-scroll :data-scroll-id="name" class="main-page-header">
        <mouse-changer :type="getType">
            <page-header :config="config" v-if="pageHeader" />
            <brand-header :data="data" v-if="animatedHeader" />
        </mouse-changer>
        <small-header v-if="smallHeader" />
        <inner-header v-if="innerHeader" />
    </div>
</template>

<script>
import BrandHeader from "@/components/PageComponents/BrandHeader";
import PageHeader from "@/components/PageComponents/PageHeader";
import SmallHeader from "@/components/PageComponents/SmallHeader";
import innerHeader from "@/components/PageComponents/InnerPageHeader";
import _ from "lodash";
import headerTypes from "@/constants/headerTypes";
import MouseChanger from "@/components/custom/MouseChanger";
import first from "lodash/first";
import { mapActions, mapGetters } from "vuex";

export default {
    props: {
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        },
        data: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        PageHeader,
        BrandHeader,
        SmallHeader,
        MouseChanger,
        innerHeader
    },
    computed: {
        ...mapGetters({
            headerType: "header/getHeaderType",
            currentPageMenuData: "menu/getCurrentPageMenuData",
            scrollBreak: "video/getScrollBreak"
        }),
        getType() {
            if (_.get(this.data, "list.0.video") === "") {
                return this.mouseTypes.ImageScale;
            }
            if (
                this.$route.fullPath.includes("brands") &&
                this.$route.params.path2 !== undefined
            ) {
                if (this.scrollBreak) {
                    return this.mouseTypes.VideoScale;
                } else {
                    return this.mouseTypes.ImageScale;
                }
            } else {
                return this.mouseTypes.Header;
            }
        },
        pageHeader() {
            return headerTypes.PAGE_HEADER === this.headerType;
        },
        animatedHeader() {
            return headerTypes.ANIMATION_HEADER === this.headerType;
        },
        smallHeader() {
            return headerTypes.SMALL_HEADER === this.headerType;
        },
        innerHeader() {
            return headerTypes.INNER_PAGE_HEADER === this.headerType;
        },
        headerData() {
            return first(this.data?.list);
        }
    },
    methods: {
        ...mapActions({
            setHeaderType: "header/setHeaderType",
            setTransparentPercentage: "header/setTransparentPercentage",
            setTransparentPercentage2: "header/setTransparentPercentage2",
            setDarkTemplate: "header/setDarkTemplate"
        })
    },
    created() {
        this.setHeaderType(this.headerData?.header_slug);
        this.setTransparentPercentage(this.headerData?.transparent_percentage);
        this.setTransparentPercentage2(
            this.headerData?.transparent_percentage2
        );
        this.setDarkTemplate(
            this.currentPageMenuData?.primary_template === "Dark"
        );
    }
};
</script>

<style lang="scss" scoped>
.main-page-header {
    overflow: hidden;
}
</style>
